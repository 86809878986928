<template>
  <div class="record">
    <p class="record-title">物料审核</p>

    <div class="summary-info">
      <div class="summary-info-item">
        <p>已审批物料类别<span>(种)</span></p>
        <p class="summary-info-title">{{800 | I1000}}</p>
      </div>
      <div class="summary-info-item">
        <p>
          当前物料类别<span>(种)</span></p>
        <p class="summary-info-title">{{200 | I1000}}</p>
      </div>
      <div class="summary-info-item">
        <p>待处理申请<span>(条)</span></p>
        <p class="summary-info-title">{{25 | I1000}}</p>
      </div>
      <div class="summary-info-item opr">
        <p>自动审核</p>
        <div class="summary-info-content">
          <a-switch checked-children="开" un-checked-children="关" default-checked/>
          <p><span class="tip">开启自动审核后，如物料充足，申请自动通过</span></p>
        </div>
      </div>
    </div>


    <div class="record-list">
      <div class="record-list-title">
        <p class="name">申请列表</p>
        <a-input-search class="cond" placeholder="请输入申请人名称" enter-button></a-input-search>
      </div>

      <a-table
        class="record-list-table"
        :columns="columns"
        :row-key="record => record.uuid"
        :data-source="records"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: '100%'}"
      >
        <template slot="onlineStatus" slot-scope="record">{{record.onlineStatus | filterStatus}}</template>
        <template slot="department" slot-scope="record">
          <span class="department-name" :title="record.departmentName">{{record.departmentName}}</span>
        </template>
        <template slot="applyAt" slot-scope="record">{{new Date(record.applyAt *
          1000).Format('yyyy-MM-dd')}}
        </template>
        <p slot="more">
          <a-button type="link" size="small">快速审核</a-button>
          <a-button type="link" size="small">查看详情</a-button>
        </p>
      </a-table>
    </div>

    <layout-footer></layout-footer>
  </div>

</template>

<script>

  export default {
    name: 'org-warehouse-record',
    components: {},
    mounted: function () {
    },
    filters: {
      filterMaintain(alarmType) {
        switch (alarmType) {
          case 1:
            return '正常'
          case 2:
            return '待维修'
          case 3:
            return '维修中'
        }
      },

      filterStatus(status) {
        switch (status) {
          case 0:
            return '初始化'
          case 1:
            return '离线'
          case 2:
            return '在线'
        }
      },

      filterUseLevel(useLevel) {
        switch (useLevel) {
          case 1:
            return '一年内'
          case 2:
            return '一至两年'
          case 3:
            return '两至三年'
          case 4:
            return '三至五年'
          case 5:
            return '五年以上'
        }
      }
    },
    data: function () {
      return {
        records: [],
        loading: false,
        columns: [
          {
            title: '申请编号',
            dataIndex: 'uuid',
            width: 120,
          },
          {
            title: '申请人',
            width: 108,
            dataIndex: 'applyEmployee',
          },
          {
            title: '申请状态',
            width: 108,
            scopedSlots: {customRender: 'maintainStatus'},
            filters: [
              {text: '全部', value: '0'},
              {text: '申请中', value: '1'},
              {text: '已挂起', value: '2'},
              {text: '已结束', value: '3'},
            ],
          },
          {
            title: '隶属部门',
            width: 240,
            dataIndex: 'department',
          },
          {
            title: '配给客户',
            width: 180,
            dataIndex: 'customer',
          },
          {
            title: '经办人',
            width: 120,
            scopedSlots: {customRender: 'price'},
          },
          {
            title: '申请时间',
            scopedSlots: {customRender: 'applyAt'},
            width: 120,
            sorter: true,
          },
          {
            title: '操作',
            fixed: 'right',
            width: 240,
            scopedSlots: {customRender: 'more'},
          },
        ],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {
      handlerecordDetail(record) {
        this.$router.push({name: 'dealer.record.detail', params: record})
      },
    },
  }
</script>

<style lang="scss" scoped>
  .record {
    padding: $container-padding;

    &-title {
      @include  font-large-s();
    }

    .summary-info {
      margin-top: $large-space;
      padding-left: $middle-space;
      border-bottom: 1px solid $container-border-color;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      &-item {
        width: 22%;
        padding-top: $small-space;

      }

      .opr {
        flex: 1;
        border-left: 1px dashed $container-border-color;
        padding-left: $large-space;
      }

      .tip {
        @include font-little();
        color: $gray-7;
      }

      &-content {
        padding: $middle-space $small-space;
      }

      &-title {
        @include  font-large-s();
        color: $color-black;
        padding: $middle-space $small-space;
      }
    }

    &-list {
      &-title {
        margin-top: $middle-space;
        display: flex;
        margin-bottom: $middle-space;

        .name {
          @include font-medium();
          font-weight: 500;
          flex: 1;
        }

        .cond {
          width: 240px;
        }
      }

      .department-name {
        display: block;
        width: 108px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }

    &-list  .ant-table {
      min-height: 200px !important;
    }

    &-list  .ant-empty-normal {
      margin: 100px 0 !important;
    }

  }
</style>
